<template>
  <div>
    <div class="row">
      <div class="col-md d-flex align-items-center">
        <div>
          <router-link :to="{ name: logoRoute }">
            <img class="logo" src="@/assets/img/logo/primary-light-stacked.svg" alt="Tip Titans" />
          </router-link>
          <h1 class="mb-5">
                404.<br> <span>Page Not Found.</span></h1>
          <p class="lead">Sorry, the page you are looking for can not be found.</p>
          <p>This can happen if:</p>
          <ul><li>The page has been moved</li> <li>The page no longer exists</li> <li>The link you were trying to access has expired</li></ul>
          <p>You can try going to our <router-link :to="{ name: 'home' }" class="text-light text-decoration-underline">home page</router-link> or if you believe this page should be here, <router-link :to="{ name: 'contact' }" class="text-light text-decoration-underline">contact us</router-link>.</p>
        </div>
      </div>
    </div>
    <!-- /.row -->
  </div>
</template>

<script>
export default {
  methods: {
    back () {
      window.history.back()
    }
  },

  computed: {
    logoRoute () {
      return this.$user.id ? 'dashboard' : 'home'
    }
  },
}
</script>
